.nearByRecommend-container {
	.content {
		padding: 24px 0;
	}

	.nearByRecommend-list {
		li {
			position: relative;
			float: left;
			padding: 0 5px;
			border-right: 1px solid #e4e4e4;
			text-align: left;
			padding-left: 32px;
			padding-right: 32px;
			cursor: pointer;
		}

		.nearByRecommend-more {
			font-size: 14px;
			color: #0086f6;
			line-height: 55px;
			border: none;
			padding: 0 0 0 20px;
		}

		.recommend-date,
		.recommend-destination {
			.icon-ow-arrow {
		        background-image: url("./icon-ow-arrow.png");
				background-repeat: no-repeat;
				width: 32px;
				height: 15px;
				display: inline-block;
				margin: 0 4px;
			}
			font-size: 14px;
			span {
				padding-left: 8px;
				color: #666;
			}
		}

		.recommend-destination {
			margin-bottom: 5px;
		}

		.recommend-price {
			font-size: 30px;
			color: #0086f6;
		}

		.recommend-price dfn {
			font-size: 14px;
		}

		.nearByRecommend-tag {
			position: absolute;
			top: 0;
			right: 4px;
			display: inline-block;
			padding: 0 2px;
			color: #00a876;
			border: 1px solid #73d8b5;
			border-radius: 2px;
			font-size: 10px;
			-webkit-transform: scale(0.88);
			-ms-transform: scale(0.88);
			transform: scale(0.88);
		}

		.recommend-destination-date {
			color: #f70;
		}

		.recommend-destination-more {
			line-height: 79px;
		}
	}
}
